import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";
import roles from "../rbac-roles";

const Content = props => (
  <tr>
    <td className="d-table-cell content-topic-cell">{props.content.topic}</td>
    <td className="d-none d-lg-table-cell">{props.content.length}</td>
    <td className="d-none d-lg-table-cell"><code>{props.content.content}</code></td>
    <td className="d-table-cell table-buttons">
      <p>
      <Can
            role={props.role}
            perform="accounts:content"
            yes={() => (<button className="btn btn-primary" onClick={() => { props.pushPage(props.content._id) }}>Page</button>)}
            no={() => (null)}
      />
      </p>
      <p>
      <Can
            role={props.role}
            perform="accounts:content"
            yes={() => (<button className="btn btn-primary" onClick={() => { props.pushPost(props.content._id) }}>Post</button>)}
            no={() => (null)}
      />
      </p>
    </td>
  </tr>
)

class AccountContent extends Component {
  constructor(props) {
    super(props);

    this.pushPage = this.pushPage.bind(this);
    this.pushPost = this.pushPost.bind(this);

    const { user } = this.props.auth0;

    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      excludedWords: "",
      content: [],
      authUser: "",
      locationCPT: false,
      seo: "",
      org: user["https://searchboss.app/org"],
      role: role,
      roles: roles,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:content")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/content/' + this.props.match.params.id, config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            excludedWords: response.data.excludedWords,
            contentContext: response.data.contentContext,
            content: response.data.content,
            authUser: response.data.authUser,
            crawling: response.data.crawling,
            seo: response.data.pages[0].h1s.join("\r\n") + "\r\n" + response.data.pages[0].title,
            isReady: true
          })

          document.title = this.state.accountname + " - content interface - searchboss"

          const url = new URL(this.state.accounturl);
          const locationURL = url.origin + "/wp-json/wp/v2/location";

          axios.get(locationURL)
            .then(res => {
              this.setState({ locationCPT: true })
            })
            .catch(err => {
              console.log(err)
            })

          this.timerID = setInterval(
            () => this.refreshContent(),
            15000
          );
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  crawling() {
    if (this.state.crawling === true) {
      return (<h4>Crawl currently in progress.</h4>)
    } else {
      return null
    }
  }

  contentList(role) {
    const contentList = this.state.content;

    return contentList.map(currentcontent => {
      return <Content content={currentcontent} pushPage={this.pushPage} pushPost={this.pushPost} role={role} key={currentcontent._id}/>;
    })
  }

  contentListLength() {
    return this.state.content.length;
  }

  contentListMonth() {
    const contentList = this.state.content;

    const today = new Date();

    var month = String(today.getMonth() + 1);
    var year = String(today.getFullYear());

    if (month < 10) {
      var month = "0" + month;
    }
    let dateFilter = year + "-" + month;

    var monthPages = contentList.filter(function (a) {
      return a.createdAt.startsWith(dateFilter);
    })

    return monthPages.length;
  }


  async refreshContent() {
    const config = await this.config();
    const newContent = await axios.get(process.env.REACT_APP_API + '/account/content/' + this.props.match.params.id, config);

    // Update state with new content by appending to existing array
    this.setState({
      content: newContent.data.content
    })
  }

  async pushPage(page) {
    if (this.state.staticRules.includes("accounts:content")) {
      const config = await this.config();
      const data = null;

      try {
        let res = await axios.post(process.env.REACT_APP_API + '/account/content/' + this.props.match.params.id + "/push/" + page + "/page", data, config);
        alert("Pushed to " + this.state.accounturl + " as a draft!");
      } catch (err) {
        alert(err);
      }
    }
  }

  async pushPost(page) {
    if (this.state.staticRules.includes("accounts:content")) {
      const config = await this.config();
      const data = null;

      try {
        let res = await axios.post(process.env.REACT_APP_API + '/account/content/' + this.props.match.params.id + "/push/" + page + "/post", data, config);
        alert("Pushed to " + this.state.accounturl + " as a draft!");
      } catch (err) {
        alert(err);
      }
    }
  }

  locationCPTOption() {
    if ( this.state.locationCPT ) {
      return ( <option key="Location" value="Location">Location</option> )
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    // initialize Yup validation
    const orderContentSchema = Yup.object().shape({
      topic: Yup.string().required("Topic is required."),
      length: Yup.number().required("Length is required as a numeric value stating the total number of words."),
      lists: Yup.string(),
      autoPush: Yup.string(),
      pushType: Yup.string(),
      nameShortcode: Yup.string(),
      geoShortcode: Yup.string(),
      requestContext: Yup.string()
    });

    var initialValues = {
      topic: "",
      length: 750,
      lists: "Yes",
      autoPush: "Yes",
      pushType: "Page",
      nameShortcode: "Yes",
      geoShortcode: "Yes",
      requestContext: "",
      seoData: this.state.seo
    };

    return (
      <Can
        role={this.state.role}
        perform="accounts:content"
        yes={() => (
          <div>
            <div className="row">
              <div className="col-lg-7">
                <h1>{this.state.accountname}</h1>
              </div>
              <div className="col-lg-5 text-lg-right">
                { this.crawling() }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <a href={ this.state.accounturl } target="_blank" rel="noreferrer">{ this.state.accounturl }</a>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>User:</strong> {this.state.user}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <p><strong>Total Pages:</strong> {this.contentListLength()}</p>
              </div>
              <div className="col-lg-5 text-lg-right">
                <p><strong>Pages This Month:</strong> {this.contentListMonth()}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <hr />
                <Formik
                  initialValues={initialValues}
                  validationSchema={orderContentSchema}
                  onSubmit={async (values) => {
                      const config = await this.config();

                      axios.post(process.env.REACT_APP_API + '/account/content/' + this.props.match.params.id + '/request/', values, config)
                        .catch(err => alert(JSON.stringify(err.response.data)))
                      alert("Content procurement started. This might take a while.")
                  }}

                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <div>
                        <h3>Order Content</h3>
                        <Form>
                          <div className="form-group">
                          <label htmlFor="topic">Topic</label>
                          <Field
                            type="text"
                            name="topic"
                            id="topic"
                            placeholder="To submit multiple topics, separate each topic with || including the spaces before and after"
                            className={
                              errors.topic && touched.topic ? "input-error" : null, "form-control"
                            }
                          />
                          <ErrorMessage name="topic" component="span" className="error" />
                          </div>
                          <div className="form-group">
                          <label htmlFor="length">Length (word count)</label>
                            <Field
                              type="number"
                              max="1000"
                              min="5"
                              name="length"
                              id="length"
                              className={
                                errors.length && touched.length ? "input-error" : null, "form-control"
                              }
                            />
                            <ErrorMessage name="length" component="span" className="error" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="autoPush">Include Lists in Content</label>
                              <Field
                                as="select"
                                name="lists"
                                id="lists"
                                className={
                                  errors.lists && touched.lists ? "input-error" : null, "form-control"
                                }
                              >
                              { <option key="Yes" value="Yes">Yes</option> }
                              { <option key="No" value="No">No</option> }
                              </Field>
                              <ErrorMessage name="lists" component="status" className="error" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="autoPush">Auto Push to API</label>
                              <Field
                                as="select"
                                name="autoPush"
                                id="autoPush"
                                className={
                                  errors.autoPush && touched.autoPush ? "input-error" : null, "form-control"
                                }
                              >
                              { <option key="No" value="No">No</option> }
                              { <option key="Yes" value="Yes">Yes</option> }
                              </Field>
                              <ErrorMessage name="autoPush" component="status" className="error" />
                          </div>
                          <div className="form-group">
                          <label htmlFor="pushType">Auto Push Type</label>
                            <Field
                              as="select"
                              name="pushType"
                              id="pushType"
                              className={
                                errors.pushType && touched.pushType ? "input-error" : null, "form-control"
                              }
                            >
                            { <option key="Page" value="Page">Page</option> }
                            { <option key="Post" value="Post">Post</option> }
                            { this.locationCPTOption() }
                            </Field>
                            <ErrorMessage name="pushType" component="status" className="error" />
                            </div>

                            <div className="form-group">
                            <label htmlFor="nameShortcode">Name Shortcode Replacement</label>
                            <small> - Uses <b>[name]</b></small><br />
                              <Field
                                as="select"
                                name="nameShortcode"
                                id="nameShortcode"
                                className={
                                  errors.nameShortcode && touched.nameShortcode ? "input-error" : null, "form-control"
                                }
                              >
                              { <option key="No" value="No">No</option> }
                              { <option key="Yes" value="Yes">Yes</option> }
                              </Field>
                              <ErrorMessage name="nameShortcode" component="status" className="error" />
                            </div>

                            <div className="form-group">
                            <label htmlFor="geoShortcode">Geo Shortcode Replacement</label>
                            <small> - Uses <b>[geo] & [region]</b></small><br />
                              <Field
                                as="select"
                                name="geoShortcode"
                                id="geoShortcode"
                                className={
                                  errors.geoShortcode && touched.geoShortcode ? "input-error" : null, "form-control"
                                }
                              >
                              { <option key="No" value="No">No</option> }
                              { <option key="Yes" value="Yes">Yes</option> }
                              </Field>
                              <ErrorMessage name="geoShortcode" component="status" className="error" />
                            </div>

                            <div className="form-group">
                            <label htmlFor="requestContext">Additional Context</label>
                              <Field
                                type="text"
                                name="requestContext"
                                id="requestContext"
                                rows="10"
                                placeholder="Add context specific to this request in addition to the account level context"
                                className={
                                  errors.requestContext && touched.requestContext ? "input-error" : null, "form-control"
                                }
                              />
                              <ErrorMessage name="requestContext" component="span" className="error" />
                              </div>
                              <div className="form-group">
                              <label htmlFor="seoData">SEO Data</label>
                                <Field
                                  component="textarea"
                                  name="seoData"
                                  id="seoData"
                                  rows="5"
                                  placeholder="Add context specific to this request in addition to the account level context"
                                  className={
                                    errors.seoData && touched.seoData ? "input-error" : null, "form-control"
                                  }
                                />
                                <ErrorMessage name="seoData" component="span" className="error" />
                                </div>

                        <div className="form-group">
                        <b>Excluded Words:</b> {this.state.excludedWords}<br />
                        <b>Content Context:</b> {this.state.contentContext}<br />
                        <b>API Auth User:</b> {this.state.authUser}<br />
                        <small>Define excluded words, content context & API auth settings in the Account via the Edit panel</small>
                        </div>
                          <button
                            type="submit"
                            className={!(dirty && isValid) ? "disabled-btn" : ""}
                            disabled={!dirty && isValid}
                          >
                            Submit
                          </button>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>

              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12">
                <h3>Existing Content</h3>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="d-table-cell">Topic</th>
                      <th className="d-none d-lg-table-cell">Length</th>
                      <th className="d-none d-lg-table-cell">Content</th>
                      <th className="d-table-cell">Push</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.contentList(this.state.role) }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        no={() => (<div><h1>WHOOPS!</h1><h2>This account level does not have permission to view account content pages.</h2></div>)}
      />
    )
  }
}

export default withAuth0(AccountContent);
