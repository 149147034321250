import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

class EditAccount extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      accountname: "",
      user: "",
      accounturl: "",
      crawling: "",
      startdate: "",
      status: "",
      boostlinks: "",
      industry: "",
      contacts: "",
      excludedWords: "",
      contentContext: "",
      authUser: "",
      authKey: "",
      productAPI: "Blaze",
      productClientID: "",
      productAuth: "",
      users: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:edit")) {
      const config = await this.config();

      axios.get(process.env.REACT_APP_API + '/account/edit/' + this.props.match.params.id,  config)
        .then(response => {
          this.setState({
            accountname: response.data.accountname,
            user: response.data.user,
            accounturl: response.data.accounturl,
            startdate: response.data.startdate,
            status: response.data.status,
            boostlinks: response.data.boostlinks,
            industry: response.data.industry,
            contacts: response.data.contacts,
            authUser: response.data.authUser,
            authKey: response.data.authKey,
            excludedWords: response.data.excludedWords,
            contentContext: response.data.contentContext,
            info: response.data.info,
            crawlSchedule: response.data.crawlSchedule,
            productAPI: response.data.productAPI,
            productClientID: response.data.productClientID,
            productAuth: response.data.productAuth,
            crawling: response.data.crawling
          })
          document.title = "edit account - " + this.state.accounturl + " - searchboss";
        })
        .catch(err => console.log(err))

        axios.get(process.env.REACT_APP_API + '/user/email/', config)
          .then(response => {
            response.data.unshift({email: "Unassigned"});
            this.setState({
              users: response.data.map(user => user.email),
              isReady: true
            });
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        return null;
      }
    }

    render() {
      if (this.state.isReady === false) {
        return <Loading />;
      }

      const initialValues = {
        accountname: this.state.accountname,
        user: this.state.user,
        accounturl: this.state.accounturl,
        crawlSchedule: this.state.crawlSchedule,
        startdate: this.state.startdate,
        status: this.state.status,
        boostlinks: this.state.boostlinks,
        industry: this.state.industry,
        contacts: this.state.contacts,
        authUser: this.state.authUser,
        authKey: this.state.authKey,
        excludedWords: this.state.excludedWords,
        contentContext: this.state.contentContext,
        productAPI: this.state.productAPI,
        productAuth: this.state.productAuth,
        productClientID: this.state.productClientID,
        info: this.state.info
      };

      // initialize Yup validation
      const editAccountSchema = Yup.object().shape({
        accountname: Yup.string().required("Account name is required."),
        user: Yup.string().required("User is required."),
        accounturl: Yup.string().url("Must be a URL starting with http:// or https://").required("URL is required."),
        startdate: Yup.date(),
        status: Yup.string(),
        boostlinks: Yup.number(),
        contacts: Yup.string(),
        excludedWords: Yup.string(),
        contentContext: Yup.string().when('authUser', {
          is: authUser => !!authUser,
          then: Yup.string().required("Content context is required when Auth User is provided."),
          otherwise: Yup.string(),
        }),
        authUser: Yup.string(),
        authKey: Yup.string(),
        productAPI: Yup.string(),
        productAuth: Yup.string(),
        productClientID: Yup.string(),
        info: Yup.string()
      });

      return (
        <Can
          role={this.state.role}
          perform="accounts:edit"
          yes={() => (
            <Formik
              initialValues={initialValues}
              validationSchema={editAccountSchema}
              enableReinitialize={true}
              onSubmit={async (values) => {
                if ( !(values.accounturl.endsWith("/")) ) {
                  values.accounturl = values.accounturl + "/";
                }

                const config = await this.config();

                if (this.state.crawling === false) {
                  try {
                    let res = await axios.post(process.env.REACT_APP_API + '/account/update/' + this.props.match.params.id, values, config);
                    alert("Account updated!");
                    setTimeout(() => { window.location = '/account/' + this.props.match.params.id + '/home/'}, 500);
                  } catch (err) {
                    alert(err.response.data);
                  }
                } else {
                  alert("Crawl in progress, account cannot be updated while account is crawling.")
                }
              }}

            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <div>
                    <h3>Edit Account</h3>
                    <hr />
                    <Form>
                      <h4>Account Info</h4>
                      <div className="form-group">
                      <label htmlFor="accountname">Account Name</label>
                      <Field
                        type="text"
                        name="accountname"
                        id="accountname"
                        className={
                          errors.accountname && touched.accountname ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="accountname" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="users">User</label>
                        <Field
                          as="select"
                          name="user"
                          id="user"
                          className={
                            errors.user && touched.user ? "input-error" : null, "form-control"
                          }
                        >
                        {
                            this.state.users.map(function(user) {
                              return <option
                                key={user}
                                value={user}>{user}
                                </option>;
                            })
                        }
                        </Field>
                        <ErrorMessage name="user" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="accounturl">URL</label>
                        <Field
                          type="string"
                          name="accounturl"
                          id="accounturl"
                          className={
                            errors.accounturl && touched.accounturl ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="accounturl" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="accounturl">Start Date</label>
                        <Field
                          type="string"
                          name="startdate"
                          id="startdate"
                          className={
                            errors.startdate && touched.startdate ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="startdate" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="status">Status</label>
                        <Field
                          as="select"
                          name="status"
                          id="status"
                          className={
                            errors.status && touched.status ? "input-error" : null, "form-control"
                          }
                        >
                        { <option key="Active" value="Active">Active</option> }
                        { <option key="Boost" value="Boost">Boost</option> }
                        { <option key="Content" value="Content">Content</option> }
                        { <option key="Sales Prospect" value="Sales Prospect">Sales Prospect</option> }
                        { <option key="On Hold" value="On Hold">On Hold</option> }
                        { <option key="Canceled" value="Canceled">Canceled</option> }
                        </Field>
                        <ErrorMessage name="status" component="status" className="error" />
                      </div>
                      <Can
                        role={this.state.role}
                        perform="accounts:boost"
                        yes={() => (
                          <>
                          <div className="form-group">
                          <label htmlFor="boostlinks">Boost Links Per Month</label>
                            <Field
                              type="string"
                              name="boostlinks"
                              id="boostlinks"
                              className={
                                errors.boostlinks && touched.boostlinks ? "input-error" : null, "form-control"
                              }
                            />
                            <ErrorMessage name="boostlinks" component="span" className="error" />
                          </div>
                          <div className="form-group">
                          <label htmlFor="industry">Industry</label>
                            <Field
                              as="select"
                              name="industry"
                              id="industry"
                              className={
                                errors.industry && touched.industry ? "input-error" : null, "form-control"
                              }
                            >
                            { <option key="Cannabis" value="Cannabis">Cannabis</option> }
                            { <option key="Home Services" value="Home Services">Home Services</option> }
                            { <option key="Other" value="Other">Other</option> }
                            </Field>
                            <ErrorMessage name="industry" component="span" className="error" />
                            </div>
                            </>
                          )}
                          no={() => (null)}
                      />
                      <div className="form-group">
                      <label htmlFor="accounturl">Contacts</label>
                        <Field
                          type="string"
                          name="contacts"
                          id="contacts"
                          className={
                            errors.contacts && touched.contacts ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="contacts" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="accounturl">Notes</label>
                        <Field
                          component="textarea"
                          name="info"
                          id="info"
                          rows="2"
                          placeholder="Not for content creation, just for your own personal notes."
                          className={
                            errors.info && touched.info ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="info" component="span" className="error" />
                      </div>
                      <hr />
                      <h4>Content Info</h4>
                      <div className="form-group">
                      <label htmlFor="authUser">API Username</label>
                        <Field
                          type="string"
                          name="authUser"
                          id="authUser"
                          placeholder="API username for content pushing. Enter Wordpress username, not the API key name."
                          className={
                            errors.authUser && touched.authUser ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="authUser" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="authKey">API Key</label>
                        <Field
                          type="string"
                          name="authKey"
                          id="authKey"
                          placeholder="API key for content pushing. Install key unencrypted."
                          className={
                            errors.authKey && touched.authKey ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="authKey" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="excludedWords">Excluded Words</label>
                        <Field
                          type="string"
                          name="excludedWords"
                          id="excludedWords"
                          placeholder="Add a comma separated list (ie: Word 1, Word 2, Word 3) of words that should be excluded from content or boost requests."
                          className={
                            errors.excludedWords && touched.excludedWords ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="excludedWords" component="span" className="error" />
                      </div>
                      <div className="form-group">
                      <label htmlFor="contentContext">Content Context</label>
                        <Field
                          component="textarea"
                          name="contentContext"
                          id="contentContext"
                          rows="10"
                          placeholder="Add any important business context (ie: slogans, year founded, certifications or other things to highlight). This field can accept a lot of information - even paragraphs. Load it up!"
                          className={
                            errors.contentContext && touched.contentContext ? "input-error" : null, "form-control"
                          }
                        />
                        <ErrorMessage name="contentContext" component="span" className="error" />
                      </div>
                      <Can
                        role={this.state.role}
                        perform="accounts:productOpt"
                        yes={() => (
                          <>
                          <hr />
                          <h3>Product Optimization Settings</h3>
                          <div className="form-group">
                          <label htmlFor="productAPI">Product API Provider</label>
                            <Field
                              as="select"
                              name="productAPI"
                              id="productAPI"
                              className={
                                errors.productAPI && touched.productAPI ? "input-error" : null, "form-control"
                              }
                            >
                            { <option key="Blaze" value="Blaze">Blaze</option> }
                            { <option key="Jane" value="Jane">Jane</option> }
                            { <option key="Range" value="Range">Range</option> }
                            </Field>
                            <ErrorMessage name="productAPI" component="span" className="error" />
                          </div>

                          <div className="form-group">
                          <label htmlFor="productClientID">Product Opt Client ID</label>
                            <Field
                              type="string"
                              name="productClientID"
                              id="productClientID"
                              placeholder="Leave blank for Blaze API connections, use Retailer ID for Range"
                              className={
                                errors.productClientID && touched.productClientID ? "input-error" : null, "form-control"
                              }
                            />
                            <ErrorMessage name="productClientID" component="span" className="error" />
                          </div>

                          <div className="form-group">
                          <label htmlFor="productAuth">Product Opt Auth Key</label>
                            <Field
                              type="string"
                              name="productAuth"
                              id="productAuth"
                              placeholder="API key or auth code for product optimization connection"
                              className={
                                errors.productAuth && touched.productAuth ? "input-error" : null, "form-control"
                              }
                            />
                            <ErrorMessage name="productAuth" component="span" className="error" />
                          </div>
                            </>
                          )}
                          no={() => (null)}
                      />
                      <button
                        type="submit"
                        className={!(dirty && isValid) ? "disabled-btn" : ""}
                        disabled={!dirty && isValid}
                      >
                        Submit
                      </button>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          )}
          no={() => (<div><h1>Whoops!</h1><h2>Your access level cannot edit an account. Sorry!</h2></div>)}
          />
      )
    }
  }

export default withAuth0(EditAccount);
