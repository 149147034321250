import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import Loading from "../components/loading";
import rules from "../rbac-rules";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';
    const role = user[roleNamespace];

    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      users: [],
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      isReady: false
    }
  }

  async config() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();

    const config = {
        headers: { Authorization: "Bearer " + token }
    };

    return config;
  }

  async componentDidMount() {
    if (this.state.staticRules.includes("accounts:add")) {
      const config = await this.config();

      document.title = "create account - searchboss";
      axios.get(process.env.REACT_APP_API + '/user/email/', config)
        .then(response => {
          response.data.unshift({email: "Unassigned"});
          this.setState({
            users: response.data.map(user => user.email),
            isReady: true
          });
        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      return null;
    }
  }

  render() {
    if (this.state.isReady === false) {
      return <Loading />;
    }

    const initialValues = {
      accountname: "",
      user: "Unassigned",
      accounturl: "",
      crawlSchedle: "",
      startdate: new Date(),
      status: "Boost",
      boostlinks: 0,
      industry: "Cannabis",
      contacts: "",
      authUser: "",
      authKey: "",
      excludedWords: "",
      contentContext: "",
      info: ""
    };

    // initialize Yup validation
    const createAccountSchema = Yup.object().shape({
      accountname: Yup.string().required("Account name is required."),
      user: Yup.string().required("User is required."),
      accounturl: Yup.string().url("Must be a URL starting with http:// or https://").required("URL is required."),
      startdate: Yup.date(),
      status: Yup.string(),
      boostlinks: Yup.number(),
      contacts: Yup.string(),
      excludedWords: Yup.string(),
      contentContext: Yup.string().when('authUser', {
        is: authUser => !!authUser,
        then: Yup.string().required("Content context is required when Auth User is provided."),
        otherwise: Yup.string(),
      }),
      authUser: Yup.string(),
      authKey: Yup.string(),
      info: Yup.string()
    });

    return (
      <Can
        role={this.state.role}
        perform="accounts:add"
        yes={() => (
          <Formik
            initialValues={initialValues}
            validationSchema={createAccountSchema}
            onSubmit={async (values) => {
                if ( !(values.accounturl.endsWith("/")) ) {
                  values.accounturl = values.accounturl + "/";
                }

                const config = await this.config();

                try {
                  let res = await axios.post(process.env.REACT_APP_API + '/account/add', values, config);
                  alert("Account created!");
                  setTimeout(() => { window.location = '/'}, 100);
                } catch (err) {
                  alert(err.response.data);
                }
            }}

          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <div>
                  <h3>Create Account</h3>
                  <hr />
                  <Form>
                    <h4>Account Info</h4>
                    <div className="form-group">
                    <label htmlFor="accountname">Account Name</label>
                    <Field
                      type="text"
                      name="accountname"
                      id="accountname"
                      className={
                        errors.accountname && touched.accountname ? "input-error" : null, "form-control"
                      }
                    />
                    <ErrorMessage name="accountname" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="users">User</label>
                      <Field
                        as="select"
                        name="user"
                        id="user"
                        className={
                          errors.user && touched.user ? "input-error" : null, "form-control"
                        }
                      >
                      {
                          this.state.users.map(function(user) {
                            return <option
                              key={user}
                              value={user}>{user}
                              </option>;
                          })
                      }
                      </Field>
                      <ErrorMessage name="user" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="accounturl">URL</label>
                      <Field
                        type="string"
                        name="accounturl"
                        id="accounturl"
                        className={
                          errors.accounturl && touched.accounturl ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="accounturl" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="accounturl">Start Date</label>
                      <Field
                        type="string"
                        name="startdate"
                        id="startdate"
                        className={
                          errors.startdate && touched.startdate ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="startdate" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="status">Status</label>
                      <Field
                        as="select"
                        name="status"
                        id="status"
                        className={
                          errors.status && touched.status ? "input-error" : null, "form-control"
                        }
                      >
                      { <option key="Active" value="Active">Active</option> }
                      { <option key="Boost" value="Boost">Boost</option> }
                      { <option key="Content" value="Content">Content</option> }
                      { <option key="Sales Prospect" value="Sales Prospect">Sales Prospect</option> }
                      { <option key="On Hold" value="On Hold">On Hold</option> }
                      { <option key="Canceled" value="Canceled">Canceled</option> }
                      </Field>
                      <ErrorMessage name="status" component="status" className="error" />
                    </div>
                    <Can
                      role={this.state.role}
                      perform="accounts:boost"
                      yes={() => (
                        <>
                        <div className="form-group">
                        <label htmlFor="boostlinks">Boost Links Per Month</label>
                          <Field
                            type="string"
                            name="boostlinks"
                            id="boostlinks"
                            className={
                              errors.boostlinks && touched.boostlinks ? "input-error" : null, "form-control"
                            }
                          />
                          <ErrorMessage name="boostlinks" component="span" className="error" />
                        </div>
                        <div className="form-group">
                        <label htmlFor="industry">Industry</label>
                          <Field
                            as="select"
                            name="industry"
                            id="industry"
                            className={
                              errors.industry && touched.industry ? "input-error" : null, "form-control"
                            }
                          >
                          { <option key="Cannabis" value="Cannabis">Cannabis</option> }
                          { <option key="Home Services" value="Home Services">Home Services</option> }
                          { <option key="Other" value="Other">Other</option> }
                          </Field>
                          <ErrorMessage name="industry" component="span" className="error" />
                          </div>
                          </>
                        )}
                        no={() => (null)}
                    />
                    <div className="form-group">
                    <label htmlFor="accounturl">Contacts</label>
                      <Field
                        type="string"
                        name="contacts"
                        id="contacts"
                        className={
                          errors.contacts && touched.contacts ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="contacts" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="accounturl">Notes</label>
                      <Field
                        component="textarea"
                        name="info"
                        id="info"
                        rows="2"
                        placeholder="Not for content creation, just for your own personal notes."
                        className={
                          errors.info && touched.info ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="info" component="span" className="error" />
                    </div>
                    <hr />
                    <h4>Content Info</h4>
                    <div className="form-group">
                    <label htmlFor="authUser">API Username</label>
                      <Field
                        type="string"
                        name="authUser"
                        id="authUser"
                        placeholder="API username for content pushing. Enter Wordpress username, not the API key name."
                        className={
                          errors.authUser && touched.authUser ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="authUser" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="authKey">API Key</label>
                      <Field
                        type="string"
                        name="authKey"
                        id="authKey"
                        placeholder="API key for content pushing. Install key unencrypted."
                        className={
                          errors.authKey && touched.authKey ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="authKey" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="excludedWords">Excluded Words</label>
                      <Field
                        type="string"
                        name="excludedWords"
                        id="excludedWords"
                        placeholder="Add a comma separated list (ie: Word 1, Word 2, Word 3) of words that should be excluded from content or boost requests."
                        className={
                          errors.excludedWords && touched.excludedWords ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="excludedWords" component="span" className="error" />
                    </div>
                    <div className="form-group">
                    <label htmlFor="contentContext">Content Context</label>
                      <Field
                        component="textarea"
                        name="contentContext"
                        id="contentContext"
                        rows="10"
                        placeholder="Add any important business context (ie: slogans, year founded, certifications or other things to highlight). This field can accept a lot of information - even paragraphs. Load it up!"
                        className={
                          errors.contentContext && touched.contentContext ? "input-error" : null, "form-control"
                        }
                      />
                      <ErrorMessage name="contentContext" component="span" className="error" />
                    </div>
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!dirty && isValid}
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
        no={() => (<div><h1>Whoops!</h1><h2>Your access level cannot add an account. Sorry!</h2></div>)}
        />
    )
  }
}

export default withAuth0(CreateAccount);
