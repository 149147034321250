import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthNav from './auth-nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../components/can";
import rules from "../rbac-rules";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.navbarClick = this.navbarClick.bind(this);

    const { user } = this.props.auth0;
    const roleNamespace = 'https://searchboss.app/role';

    var role = "";
    if (user !== undefined) {
      role = user[roleNamespace];
    } else {
      role = "";
    }
    var staticRules = "";
    var dynamicRules = "";

    if (rules.hasOwnProperty(role)) {
      var currentRules = rules[role];
      staticRules = currentRules.static;
      dynamicRules = currentRules.dynamic;
    } else {
      staticRules = "";
      dynamicRules = "";
    }

    this.state = {
      role: role,
      staticRules: staticRules,
      dynamicRules: dynamicRules,
      mobileToggler: "collapse",
      ariaExpanded: "false",
      navbarClass: "collapse navbar-collapse"
    };
  }

    navbarClick() {
      if (this.state.mobileToggler === "collapse") {
        this.setState({
          mobileToggler: "show",
          ariaExpanded: "true",
          navbarClass: "collapse navbar-collapse show"
        })
      } else {
        this.setState({
          mobileToggler: "collapse",
          ariaExpanded: "false",
          navbarClass: "collapse navbar-collapse"
        })
      }
     }


  render() {
    return (
      <nav className='navbar navbar-dark fixed-top bg-dark navbar-expand-lg'>
        <Link to="/" className="navbar-brand">searchboss</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle={this.state.mobileToggler} data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded={this.state.areaExpanded} aria-label="Toggle navigation" onClick={() => { this.navbarClick() } }>
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className={this.state.navbarClass} id="navbarToggler">
        <ul className="nav navbar-nav mr-auto">
          <Can
            role={this.state.role}
            perform="accounts:view"
            yes={() => (
              <li className="navbar-item">
                <Link to="/" className="nav-link" onClick={() => { this.navbarClick() } }>Accounts</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="accounts:add"
            yes={() => (
              <li className="navbar-item">
                <Link to="/account/create" className="nav-link" onClick={() => { this.navbarClick() } }>Create Account</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="users:view"
            yes={() => (
              <li className="navbar-item">
                <Link to="/users/" className="nav-link" onClick={() => { this.navbarClick() } }>Users</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="users:add"
            yes={() => (
              <li className="navbar-item">
                <Link to="/user/create" className="nav-link" onClick={() => { this.navbarClick() } }>Create User</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="accounts:boost"
            yes={() => (
              <li className="navbar-item">
                <Link to="/boost/domains" className="nav-link" onClick={() => { this.navbarClick() } }>Boost</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="accounts:boost"
            yes={() => (
              <li className="navbar-item">
                <Link to="/boost/create" className="nav-link" onClick={() => { this.navbarClick() } }>Create Domain</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="orgs:stats"
            yes={() => (
              <li className="navbar-item">
                <Link to="/org/stats" className="nav-link" onClick={() => { this.navbarClick() } }>Usage Stats</Link>
              </li>
            )}
            no={() => ("")}
          />
          <Can
            role={this.state.role}
            perform="orgs:add"
            yes={() => (
              <li className="navbar-item">
                <Link to="/org/create" className="nav-link" onClick={() => { this.navbarClick() } }>Create Organization</Link>
              </li>
            )}
            no={() => ("")}
          />
        </ul>
        <span className="d-none d-lg-block">
          <AuthNav />
        </span>
        </div>
      </nav>
    );
  }
}

export default withAuth0(Navbar);
