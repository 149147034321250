import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from "./components/navbar.component";
import CreateOrg from "./views/create-organization.component";
import EditOrg from "./views/edit-org.component";
import OrgStats from "./views/org-stats.component";
import AccountList from "./views/account-list.component";
import EditAccount from "./views/edit-account.component";
import CreateAccount from "./views/create-account.component";
import UsersList from "./views/users-list.component";
import CreateUser from "./views/create-user.component";
import EditUser from "./views/edit-user.component";
import AccountHome from "./views/account-home.component";
import AccountPages from "./views/account-pages.component";
import AccountPage from "./views/account-page.component";
import AccountContent from "./views/account-content.component";
import AccountContentExisting from "./views/account-content-existing.component";
import AccountBoost from "./views/account-boost.component";
import AccountProductOpt from "./views/account-product-opt.component";
import BoostList from "./views/boost-list.component";
import CreateDomain from "./views/create-domain.component";
import EditDomain from "./views/edit-domain.component";
import Profile from "./views/profile.component";
import NotFound from "./views/not-found.component";

import ProtectedRoute from "./auth/protected-route";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/loading";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

   return (
       <div className="container">
         <Navbar />
         <br/>
         <Switch>
           <ProtectedRoute path="/" exact component={AccountList} />
           <ProtectedRoute path="/org/create" component={CreateOrg} />
           <ProtectedRoute path="/org/stats" component={OrgStats} />
           <ProtectedRoute path="/org/edit" component={EditOrg} />
           <ProtectedRoute path="/account/:id/home" component={AccountHome} />
           <ProtectedRoute path="/account/:id/pages" component={AccountPages} />
           <ProtectedRoute path="/account/:account/page/:page" component={AccountPage} />
           <ProtectedRoute path="/account/:id/edit" component={EditAccount} />
           <ProtectedRoute path="/account/create" component={CreateAccount} />
           <ProtectedRoute path="/:id/content" component={AccountContent} />
           <ProtectedRoute path="/:id/content-existing" component={AccountContentExisting} />
           <ProtectedRoute path="/:id/boost" component={AccountBoost} />
           <ProtectedRoute path="/:id/productOpt" component={AccountProductOpt} />
           <ProtectedRoute path="/boost/domains" component={BoostList} />
           <ProtectedRoute path="/boost/create" component={CreateDomain} />
           <ProtectedRoute path="/boost/:id/edit" component={EditDomain} />
           <ProtectedRoute path="/users/" component={UsersList} />
           <ProtectedRoute path="/user/create" component={CreateUser} />
           <ProtectedRoute path="/user/:id/edit/" component={EditUser} />
           <ProtectedRoute path="/profile/" component={Profile} />
           <ProtectedRoute component={NotFound} />
         </Switch>
       </div>
    );
}

export default App;
